import React, { useState } from 'react';
import { Box, Card, CardActions, CardContent, Button, Typography } from '@mui/material';
import startLottery from '../contracts/startLottery';
import TicketIcon from '@mui/icons-material/ConfirmationNumberOutlined';

export default function StartLottery({ web3, accounts, lotteryContract, openPopup }) {
  const [numberOfTickets, setNumberOfTickets] = useState(0);
  const [ticketPrice, setTicketPrice] = useState(0);

  const handleNumberOfTicketsChange = (e) => {
    setNumberOfTickets(e.target.value);
  };

  const handleTicketPriceChange = (e) => {
    setTicketPrice(e.target.value);
  };

  return (
    <Box sx={{ width:"350px" }}>
      <Card >
        <CardContent>
          <Typography variant="h5" sx={{ display: "flex", alignItems: "center", mb: 1.5, borderRadius: "50px", backgroundColor: "#342134" }} color={"white"}>
            <TicketIcon sx={{ marginRight: 2, backgroundColor: "primary.main", borderRadius: "50%", padding: "10px", width: "45px", height: "45px" }} />
            Iniciar Lotería
          </Typography>
          <input type="number" id="number-of-tickets" placeholder='Número de Tickets:' value={numberOfTickets===0?"":numberOfTickets} onChange={handleNumberOfTicketsChange} min="1" style={{background:"transparent",color:"#000",fontSize:"16px",border:"none",display: "flex", padding: "8px 16px", borderRadius: "50px", backgroundColor: "#fff", width:"100%" }} />
          <input type="number" id="ticket-price" placeholder='Precio del Ticket (en MATIC):' value={ticketPrice===0?"":ticketPrice} onChange={handleTicketPriceChange} min="0.001" step="0.001" style={{marginTop:"15px",background:"transparent",color:"#000",fontSize:"16px",border:"none",display: "flex", padding: "8px 16px", borderRadius: "50px", backgroundColor: "#fff", width:"100%" }} />
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => startLottery(web3, lotteryContract, accounts, numberOfTickets, ticketPrice, openPopup)}
            sx={{ color:"#342134", display: "flex", flex:1, paddingX: 2, paddingY: 1, marginX:1, alignItems: "center", borderRadius: "50px",fontWeight:"bold" }}
            >
            Iniciar Lotería
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
