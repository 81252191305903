import * as React from 'react';

import { Typography, Button } from '@mui/material';

import { AppBar, Toolbar, Menu, MenuItem } from '@mui/material';
import { Person, Language } from '@mui/icons-material';
function AppAppBar({connectWallet,accounts}) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1,alignItems:"center",justifyContent:"flex-start",display:"flex" }}>
          <img src={"./logo.png"} alt="Lottery Rat" width={40} height={40} />
          <span style={{ color: '#222' }}>LotteryRat</span>
        </Typography>
        {accounts.length === 0 ?
        <Button color="inherit" startIcon={<Person />} onClick={connectWallet}>
          Login
        </Button>
        :
        <p>{accounts[0]}</p>
        }
        <Button color="inherit" onClick={handleMenu} >
            <Language />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>English</MenuItem>
          <MenuItem onClick={handleClose}>Español</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default AppAppBar;
