import React from 'react';
import { Box, Card, CardActions, CardContent, Button, Typography } from '@mui/material';
import pickWinner from '../contracts/pickWinner';
import TicketIcon from '@mui/icons-material/ConfirmationNumberOutlined';

export default function PickWinner({ accounts, lotteryContract, openPopup }) {
  return (
    <Box sx={{ width:"350px" }}>
      <Card >
        <CardContent>
          <Typography variant="h5" sx={{ display: "flex", alignItems: "center", borderRadius: "50px", backgroundColor: "#342134" }} color={"white"}>
            <TicketIcon sx={{ marginRight: 2, backgroundColor: "primary.main", borderRadius: "50%", padding: "10px", width: "45px", height: "45px" }} />
            Seleccionar Ganador
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => pickWinner(lotteryContract, accounts, openPopup)}
            sx={{ color:"#342134", display: "flex", flex:1, paddingX: 2, paddingY: 1, marginX:1, alignItems: "center", borderRadius: "50px",fontWeight:"bold" }}
            >
            Seleccionar Ganador
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
