import Web3 from 'web3';
import handleTransactionError from '../utils/handleTransactionError';

const startLottery = async (web3, lotteryContract, accounts, numberOfTickets, ticketPrice, openPopup) => {
  if (!numberOfTickets || !ticketPrice) {
    openPopup('Error', 'Por favor, ingresa un número de tickets y un precio de ticket válidos.');
    return;
  }

  try {
    const web3Instance = new Web3(web3.currentProvider); // Asegúrate de que web3 esté correctamente inicializado
    const ticketPriceWei = web3Instance.utils.toWei(ticketPrice.toString(), 'ether');
    console.log('Ticket price in Wei:', ticketPriceWei);

    const balance = await web3Instance.eth.getBalance(accounts[0]);
    const balanceInEther = web3Instance.utils.fromWei(balance, 'ether');
    console.log('Account balance:', balanceInEther, 'MATIC');

    if (parseFloat(balanceInEther) < parseFloat(ticketPrice)) {
      openPopup('Error de Fondos', 'No tienes suficientes fondos para iniciar la lotería. Por favor, asegúrate de tener suficiente MATIC en tu cuenta.');
      return;
    }

    await lotteryContract.methods.startLottery(numberOfTickets, ticketPriceWei).send({
      from: accounts[0],
      //gas: 1250000 // Establecer gas fijo a 30000
    })
      .on('transactionHash', (hash) => {
        console.log('Transaction hash:', hash);
      })
      .on('receipt', (receipt) => {
        console.log('Receipt:', receipt);
        openPopup('Éxito', 'La lotería ha sido iniciada exitosamente.');
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        console.log('Confirmation number:', confirmationNumber);
      })
      .on('error', (error) => {
        console.log('Transaction error:', error);
        handleTransactionError(error, openPopup);
      });
  } catch (error) {
    console.log('Error starting lottery:', error);
    handleTransactionError(error, openPopup);
  }
};

export default startLottery;
