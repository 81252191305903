import getWeb3 from './web3';
import abi from "../abi.json";

const contractAddress = "0x4934258fc50cCdd124b5bc149c1E32ae2c1aCD9a";
const desiredNetworkId = "137"; // Example: Polygon mainnet
const networkParams = {
  chainId: '0x89', // Hexadecimal representation
  chainName: 'Polygon Mainnet',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18
  },
  rpcUrls: ['https://rpc-mainnet.matic.network'],
  blockExplorerUrls: ['https://polygonscan.com/']
};

const init = async (setWeb3, setAccounts, setLotteryContract, setLotteryData, openPopup) => {
  try {
    const web3Instance = await getWeb3();
    setWeb3(web3Instance);

    const currentNetworkId = await web3Instance.eth.net.getId();

    if (currentNetworkId !== parseInt(desiredNetworkId, 10)) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: networkParams.chainId }]
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [networkParams]
            });
          } catch (addError) {
            openPopup('Error', 'No se pudo agregar la red.', addError.message);
            return;
          }
        } else {
          openPopup('Error', 'No se pudo cambiar a la red correcta.', switchError.message);
          return;
        }
      }
    }

    

    const accounts = await web3Instance.eth.getAccounts();
    setAccounts(accounts);
    const lotteryInstance = new web3Instance.eth.Contract(abi, contractAddress);
    setLotteryContract(lotteryInstance);

    const fetchCurrentLottery = async () => {
      if (lotteryInstance) {
        try {
          const data = await lotteryInstance.methods.getCurrentLottery().call();
          
          const participantCounts = data.participants.reduce((acc, { addr }) => {
            if (acc[addr]) {
              acc[addr]++;
            } else {
              acc[addr] = 1;
            }
            return acc;
          }, {});
          
          const participants = Object.keys(participantCounts).map(addr => ({
            wallet: addr,
            tickets: participantCounts[addr]
          }));
          
          setLotteryData({
            numberOfTickets: data.numberOfTickets.toString(),
            currentNumberOfTickets: data.currentNumberOfTickets.toString(),
            ticketPriceUnformated: data.ticketPrice,
            ticketPrice: web3Instance.utils.fromWei(data.ticketPrice.toString(), 'ether'),
            feePercent: data.feePercent.toString(),
            ended: data.ended,
            participants: participants,
            winner: `${data.winner.addr} has won ${web3Instance.utils.fromWei(data.winner.amount.toString(), 'ether')} MATIC`
          });
          console.log({data})
        } catch (error) {
          openPopup('Error al Obtener Datos', 'No se pudieron obtener los datos de la lotería actual. Por favor, intenta recargar la página.');
          console.log('Failed to fetch current lottery data:', error);
        }
      }
    };

    fetchCurrentLottery();
  } catch (error) {
    openPopup('MetaMask No Instalado', 'No se detectó MetaMask en tu navegador. Por favor, instala MetaMask para continuar.');
  }
};

export default init;
