import React, { useState, useEffect } from 'react';

import { Container, Box,Typography, Grid,Card, CardContent, Toolbar } from '@mui/material';

import AppAppBar from './components/AppAppBar';
import init from './utils/init';
import BuyTicket from './components/BuyTicket';
import AppModal from './components/AppModal';
import getOwners from './contracts/getOwners';
import FAQ from './components/FAQ';
import StartLottery from "./components/StartLottery"
import PickWinner from "./components/PickWinner"

const connectWalletHandler = async (web3, setAccounts, openPopup) => {
  try {
    const accounts = await web3.eth.requestAccounts();
    setAccounts(accounts);
  } catch (error) {
    openPopup('Error de Conexión', 'No se pudo conectar a la wallet. Por favor, asegúrate de que MetaMask esté instalado y que hayas aprobado la conexión.')
    console.log('Failed to connect wallet: ' + error.message);
  }
};

export default function App() {
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [lotteryContract, setLotteryContract] = useState(null);
  const [lotteryData, setLotteryData] = useState(null);
  const [open, setOpen] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [descriptionModal, setDescriptionModal] = useState("");
  const [isOwner, setIsOwners] = useState(null);

  const openPopup = (title, description) => {
    setTitleModal(title);
    setDescriptionModal(description);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    init(setWeb3, setAccounts, setLotteryContract, setLotteryData, openPopup);
    
    const fetchOwners = async () => {
      const ownersList = await getOwners(lotteryContract, openPopup);
      
      try{
        const _isOwner = accounts.some(e => ownersList.includes(e));
        setIsOwners(_isOwner)
      } catch {
        console.log('Error fetching owners')
      }
    };

    if (lotteryContract) {
      fetchOwners();
    }

	
  }, [lotteryContract,lotteryData,accounts]);


  return (
    <Box >
      {lotteryData&&
      <Container sx={{maxWidth:"100%!important",padding:"0px!important"}}>
        <AppAppBar connectWallet={() => connectWalletHandler(web3, setAccounts, openPopup)} accounts={accounts} />

        <Toolbar sx={{ bgcolor: '#222222', color: 'white', padding:"30px 0" }}>
          <Container sx={{display:"flex"}}>
            <Box sx={{flex:1}}>
              <Typography sx={{ fontWeight:"bold",fontSize:"32px" }} color={"white"}>
                ¡Bienvenido a Lottery Rat! 🐭💰
              </Typography>
              <Typography color={"white"} sx={{fontWeight:"bold",marginTop:2,}}>
              ¿Cansado de ser pobre como una rata? ¡Es tu momento de brillar!
              </Typography>
              <Typography color={"white"} sx={{fontWeight:"bold",marginTop:2,}}>
              Cómo funciona?
              </Typography>
              <Typography color={"white"} sx={{maxWidth:"80%",marginTop:2,}}>
             Apuesta desde {lotteryData.ticketPrice} MATIC y entra en el sorteo de un pozo de {lotteryData.numberOfTickets} MATIC. Cuando el pozo llega al límite, un smart contract selecciona una wallet al azar.
              </Typography>
              <Typography color={"white"} sx={{maxWidth:"80%"}}>
              ¡Podrías ser tú quien convierta unos pocos centavos en un montón de queso! No se necesita más que un poco de suerte roedora para ganar.
              </Typography>
              <Typography color={"white"} sx={{fontWeight:"bold",marginTop:2,maxWidth:"80%"}}>
              ¿Listo para cambiar tu suerte? ¡Participa ahora en Lottery Rat y que la fortuna te sonría!
              </Typography>
            </Box>
            <BuyTicket web3={web3} accounts={accounts} lotteryContract={lotteryContract} lotteryData={lotteryData} openPopup={openPopup} />

          </Container>
        </Toolbar>

        <Container >
          <Box sx={{ marginTop: 2, display:"flex",gap:2}}>
            <Card sx={{ backgroundColor:"#342134",flex:1 }}>
              <CardContent sx={{display: "flex",flexDirection:"row"}}>
                <Box sx={{alignItems: "center",display: "flex",flexDirection:"column",flex:1}}>
                  <Typography sx={{ fontWeight:"bold",fontSize:"32px" }} color={"white"}>
                    {lotteryData && lotteryData.numberOfTickets}
                  </Typography>
                  <Typography color={"white"}>
                    Tickets Totales
                  </Typography>
                </Box>
                </CardContent>
            </Card>
              <Card sx={{ backgroundColor:"#342134",flex:1 }}>
                <CardContent sx={{display: "flex",flexDirection:"row"}}>
                <Box sx={{alignItems: "center",display: "flex",flexDirection:"column",flex:1}}>
                  <Typography sx={{ fontWeight:"bold",fontSize:"32px" }} color={"white"}>
                  {lotteryData && lotteryData.currentNumberOfTickets}
                  </Typography>
                  <Typography color={"white"}>
                  Tickets Vendidos
                  </Typography>
                </Box>
                </CardContent>
            </Card>
              <Card sx={{ backgroundColor:"#342134",flex:1 }}>
                <CardContent sx={{display: "flex",flexDirection:"row"}}>
                <Box sx={{alignItems: "center",display: "flex",flexDirection:"column",flex:1}}>

                  <Typography sx={{ fontWeight:"bold",fontSize:"32px" }} color={"white"}>
                  {lotteryData && (lotteryData.numberOfTickets-lotteryData.currentNumberOfTickets)}
                  </Typography>
                  <Typography color={"white"}>
                  Tickets Disponibles
                  </Typography>

                </Box>
                </CardContent>
            </Card>
              <Card sx={{ backgroundColor:"#342134",flex:1 }}>
                <CardContent sx={{display: "flex",flexDirection:"row"}}>
                <Box sx={{alignItems: "center",display: "flex",flexDirection:"column",flex:1}}>

                  <Typography sx={{ fontWeight:"bold",fontSize:"32px" }} color={"white"}>
                  {lotteryData && lotteryData.ticketPrice} MATIC
                  </Typography>
                  <Typography color={"white"}>
                  Precio del Ticket
                  </Typography>
                </Box>
              </CardContent>
            </Card>

          </Box>



          <Box sx={{marginTop:3,paddingTop:3,borderTop:"1px solid #ccc"}}>
            <Box sx={{display:"flex"}}>
              <Box sx={{flex:1}} >
                <Typography sx={{ mb: 3 }} variant="h5" component="div">
                Tickets Comprados
                </Typography>

                <Grid container spacing={3} >
                  {lotteryData.participants.length > 0 && lotteryData.participants.map((participant, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>

                      <Card>
                        <CardContent sx={{padding:0}}>
                          <Box sx={{backgroundImage:"url(./matic.png)",height:"120px",backgroundSize:"cover",backgroundPosition:"center",display:"flex",alignItems:"center"}}>
                            <Typography sx={{margin:2,overflow:"hidden",color:"#fff",fontSize:"21px"}}>
                              {participant.wallet}
                            </Typography>
                          </Box>
                        </CardContent>
                        
                        <CardContent>
                          <Typography variant="body2" color="text.secondary">
                            Tickets Comprados:
                            <Typography component="span" color="warning.main" fontWeight="bold"> {participant.tickets}</Typography>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>

                    ))}
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box sx={{marginTop:10,borderTop:"1px solid #ccc"}}>
           <FAQ></FAQ>
          </Box>

          <Box sx={{marginTop:10}}>
            {isOwner&&<StartLottery web3={web3} accounts={accounts} lotteryContract={lotteryContract} openPopup={openPopup} />}
            {isOwner&&<PickWinner accounts={accounts} lotteryContract={lotteryContract} openPopup={openPopup} />}

          </Box>
        </Container>
      </Container>}
      <AppModal open={open} handleClose={handleClose} title={titleModal} description={descriptionModal} />
    </Box>
  );
}


/*
 <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={4}>
              <Box sx={{display:"flex"}}>
                <BuyTicket web3={web3} accounts={accounts} lotteryContract={lotteryContract} lotteryData={lotteryData} openPopup={openPopup} />
                {isOwner&&<StartLottery web3={web3} accounts={accounts} lotteryContract={lotteryContract} openPopup={openPopup} />}
                {isOwner&&<PickWinner accounts={accounts} lotteryContract={lotteryContract} openPopup={openPopup} />}
              </Box>
          </Box>

*/