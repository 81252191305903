import handleTransactionError from '../utils/handleTransactionError';

const getOwners = async (lotteryContract, openPopup) => {
  try {
    const owners = await lotteryContract.methods.getOwners().call();
    console.log('Owners:', owners);
    return owners;
  } catch (error) {
    console.log('Error fetching owners:', error);
    handleTransactionError(error, openPopup);
  }
};

export default getOwners;
