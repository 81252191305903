import React, { useState } from 'react';
import { Box, Card, CardActions, CardContent, Button, Typography } from '@mui/material';
import TicketIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import buyTicket from '../contracts/buyTicket';

export default function BuyTicket({ web3, accounts, lotteryContract, lotteryData, openPopup }) {
  const [ticketCount, setTicketCount] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  return (
    <Box sx={{ display: "flex" }}>
      <Card >
        <CardContent >
          <Typography variant="h5" sx={{ display: "flex", alignItems: "center", mb: 1.5, borderRadius: "50px", backgroundColor: "#342134" }} color={"white"}>
            <TicketIcon sx={{ marginRight: 2, backgroundColor: "primary.main", borderRadius: "50%", padding: "10px", width: "45px", height: "45px" }} />
            Participar
          </Typography>
          <Box sx={{display:"flex"}}>
            <Button color="primary" variant="contained" sx={{ color:"#fff", display: "flex",fontSize:"18px", alignItems: "center", borderRadius: "50px",fontWeight:"bold",height:"40px" }}
              onClick={(e) => {
                if(ticketCount>0){
                  const newCount = ticketCount-1
                  setTicketCount(newCount)
                  setTotalCost(newCount * Number(lotteryData.ticketPriceUnformated));
                }
              }}>
              -
            </Button>
            <p type="number" id="number-of-tickets" style={{color:"#fff",fontSize:"16px",border:"none",display: "flex", padding: "8px 30px",margin:"0 10px", borderRadius: "50px", backgroundColor: "#342134",textAlign:"center"}} >{ticketCount}</p>
            <Button color="primary" variant="contained" sx={{ color:"#fff", display: "flex",fontSize:"18px", alignItems: "center", borderRadius: "50px",fontWeight:"bold",height:"40px" }}
              onClick={(e) => {
                if(ticketCount<(lotteryData.numberOfTickets-lotteryData.currentNumberOfTickets)){
                  const newCount = ticketCount+1
                  setTicketCount(newCount)
                  setTotalCost(newCount * Number(lotteryData.ticketPriceUnformated));
                }
              }}>
              +
            </Button>
          </Box>
          <Typography sx={{ display: "flex", paddingX: 1,  mt: 3}} color={"#342134"}>
              Total a pagar: {web3.utils.fromWei(totalCost.toString(), 'ether')} MATIC
          </Typography>
        </CardContent>

        <CardActions sx={{marginBottom:"10px"}}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if(ticketCount>0){
                buyTicket(web3, lotteryContract, accounts, ticketCount, lotteryData, openPopup)
              }
            }}
            sx={{ color:"#342134", display: "flex", flex:1, paddingX: 2, paddingY: 1,marginX:1, alignItems: "center", borderRadius: "50px",fontWeight:"bold" }}
          >
            Comprar ticket
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
