/*
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Lottery from './Lottery';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Lottery />
  </React.StrictMode>
);
*/

import React from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider, createTheme } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import App from './Lottery'

const theme = createTheme({
  palette: {
    primary: {
      main: '#e74c3c',
    },
  },
})

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
)